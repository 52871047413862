fetch("/keywords").then(function (response) {
	if (response.ok) {
		return response.json();
	}
	throw response;
}).then(function (data) {
	[].forEach.call(document.getElementsByClassName("drug-search"), function (input) {
		autocomplete(input, data, {
			select: function (element) {
				window.location.href = "/drugs/" + element.dataset.value;
			}
		});
	});
}).catch(function (error) {
	console.warn(error);
});


function autocomplete(input, items, options)
{
	options = options || {};

	input.setAttribute("autocomplete", "off");

	var currentFocus;
	var itemSelected;

	input.addEventListener("input", function(e) {

		var value = this.value;

		closeAllLists();

		if (!value) return false;

		currentFocus = -1;
		itemSelected = false;

		var a = document.createElement("div");
		a.setAttribute("id", this.id + "-autocomplete-list");
		a.setAttribute("class", "autocomplete-items");

		this.parentNode.appendChild(a);

		for (var label in items)
		{
			if (label.substr(0, value.length).toUpperCase() == value.toUpperCase())
			{
				b = document.createElement("div");
				b.setAttribute("data-value", items[label]);
				b.innerHTML = "<strong>" + label.substr(0, value.length) + "</strong>" + label.substr(value.length);
				b.addEventListener("click", function (e) {
					itemSelected = true;
					if (options.select)
						options.select(this);
					else
						input.value = this.textContent;
				});
				a.appendChild(b);
			}

			if (a.childElementCount >= 10) break;
		}
	});

	input.addEventListener("keydown", function (e) {

		var x = document.getElementById(this.id + "-autocomplete-list");

		if (x) x = x.getElementsByTagName("div");

		if (e.keyCode == 40) // down
		{
			currentFocus++;
			addActive(x);
		}
		else if (e.keyCode == 38) // up
		{
			currentFocus--;
			addActive(x);
		}
		else if (e.keyCode == 27) // esc
		{
			closeAllLists();
		}
		else if (e.keyCode == 13) // return
		{
			if (currentFocus > -1 && x)
			{
				e.preventDefault();
				x[currentFocus].click();
			}
		}
	});

	input.addEventListener("change", function (e) {
		if (options.strict && !itemSelected) this.value = '';
	});

	function addActive(x)
	{
		if (!x) return false;

		removeActive(x);

		if (currentFocus >= x.length) currentFocus = 0;

		if (currentFocus < 0) currentFocus = (x.length - 1);

		x[currentFocus].classList.add("autocomplete-active");
	}

	function removeActive(x)
	{
		for (var i = 0; i < x.length; i++)
		{
			x[i].classList.remove("autocomplete-active");
		}
	}

	function closeAllLists()
	{
		var wrappers = document.getElementsByClassName("autocomplete-items");

		for (var i = 0; i < wrappers.length; i++)
		{
			wrappers[i].parentNode.removeChild(wrappers[i]);
		}
	}

	document.addEventListener("click", function (e) {
		closeAllLists();
	});

	document.addEventListener("scroll", function (e) {
		closeAllLists();
	});
}
