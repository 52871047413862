// Enable popovers

[].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	.map(function (element) {
		return new bootstrap.Popover(element);
	});

// Show modal on page load

[].forEach.call(document.getElementsByClassName("modal-show-on-load"), function (element) {
	var modal = new bootstrap.Modal(element);
	modal.show();
});
